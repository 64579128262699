import React from "react";
import Widget from "components/widget/Widget";
import { IoMdLogIn } from "react-icons/io";
import { PiHandshakeFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import Navbar from "../../components/footer/Footer";

export default function Home() {
  return (
    <>
      <div className="flex h-[100vh] w-full flex-col items-center justify-between">
        <div className="flex h-full w-full flex-col items-center justify-center gap-5">
          <Link to="/auth">
            <Widget
              icon={<IoMdLogIn className="h-7 w-7" />}
              isSelect={"No"}
              subtitle={"Login"}
            />
          </Link>
          <Link to="/pre-booking">
            <Widget
              icon={<PiHandshakeFill className="h-7 w-7" />}
              isSelect={"No"}
              subtitle={"Book Advance Visit"}
            />
          </Link>
        </div>
        <Navbar />
      </div>
    </>
  );
}
