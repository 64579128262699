import Banner from "./components/Banner";
import NFt2 from "assets/img/nfts/Nft2.png";
import NFt4 from "assets/img/nfts/Nft4.png";
import NFt3 from "assets/img/nfts/Nft3.png";
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import react, { useState } from "react";
import tableDataTopCreators from "views/admin/visitors/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/visitors/variables/tableColumnsTopCreators";
import Card from "components/card";
import TodayVisitor from "./components/TodayVisitor";
import AddMember from "./components/AddMember";
import NftCard from "../../../components/card/NftCard";
import ProfileCard from "./components/ProfileCard";
import General from "../profile/components/General";
import Form from "./components/Form";
import { FaArrowLeft } from "react-icons/fa6";

const Visitors = () => {
  const [showInfo, setShowInfo] = useState(true);
  const [edit, setEdit] = useState(true);
  const [tableFormat, setTableFormat] = useState(true);

  const handleEdit = () => {
    setEdit(!edit);
  };

  const showProfile = (data) => {
    setShowInfo(data);
  };

  const handleShowAllProfileCards = () => {
    setShowInfo(true);
    setEdit(true);
  };
  const handleTableFormat = () => {
    setTableFormat(!tableFormat);
  };
  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        {/* NFt Banner */}
        <Banner />

        {/* Header */}
        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            All Members
          </h4>
          {showInfo ? (
            <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
              <li>
                <a
                  className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                  href=" "
                >
                  All
                </a>
              </li>
              <li>
                <a
                  className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                  href=" "
                >
                  Admin
                </a>
              </li>
              <li>
                <a
                  className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                  href=" "
                >
                  Visitors
                </a>
              </li>
              <li>
                <a
                  className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                  href=" "
                >
                  Employees
                </a>
              </li>
              <li>
                <a
                  className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                  href=" "
                >
                  Staff
                </a>
              </li>
            </ul>
          ) : (
            <>
              {edit ? (
                <button
                  onClick={handleEdit}
                  className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                >
                  Edit
                </button>
              ) : (
                <button
                  onClick={handleEdit}
                  className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                >
                  Save
                </button>
              )}
            </>
          )}
        </div>

        {showInfo ? (
          /* Profile Card */
          <>
            <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
              <NftCard
                bidders={[avatar1, avatar2, avatar3]}
                title="Abstract Colors"
                author="Esthera Jackson"
                price="0.91"
                image={NFt3}
                sendDataToParent={showProfile}
              />
              <NftCard
                bidders={[avatar1, avatar2, avatar3]}
                title="ETH AI Brain"
                author="Nick Wilson"
                price="0.7"
                image={NFt2}
                sendDataToParent={showProfile}
              />
              <NftCard
                bidders={[avatar1, avatar2, avatar3]}
                title="Mesh Gradients"
                author="Will Smith"
                price="2.91"
                image={NFt4}
                sendDataToParent={showProfile}
              />
              <NftCard
                bidders={[avatar1, avatar2, avatar3]}
                title="ETH AI Brain"
                author="Nick Wilson"
                price="0.7"
                image={NFt2}
                sendDataToParent={showProfile}
              />
              <NftCard
                bidders={[avatar1, avatar2, avatar3]}
                title="Mesh Gradients"
                author="Will Smith"
                price="2.91"
                image={NFt4}
                sendDataToParent={showProfile}
              />
              <NftCard
                bidders={[avatar1, avatar2, avatar3]}
                title="Abstract Colors"
                author="Esthera Jackson"
                price="0.91"
                image={NFt3}
                sendDataToParent={showProfile}
              />
            </div>
            <button className="text-black linear mt-5 h-[50px] w-[120px] rounded-xl bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white active:!bg-white">
              See All
            </button>
          </>
        ) : (
          <Card extra="flex flex-col bg-white w-full rounded-3xl min-w-[400px] ">
            <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white py-4 px-4 pb-[20px] shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
              <button
                onClick={handleShowAllProfileCards}
                className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
              >
                <FaArrowLeft />
              </button>
              <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                Profile
              </h4>
            </div>
            <div className="flex flex-col justify-around gap-5 px-4 pt-5 md:flex-row">
              <div className="col-4 flex flex-col">
                <ProfileCard />
              </div>
              <Form />
            </div>
            <div className="generalInfo mt-5">
              <General />
            </div>
          </Card>
        )}
      </div>

      {/* right side section */}

      <div className="col-span-1 flex h-full w-full flex-col gap-5 rounded-xl 2xl:col-span-1">
        <AddMember
          extra="mb-5"
          tableData={tableDataTopCreators}
          columnsData={tableColumnsTopCreators}
        />
        <TodayVisitor
          extra="mb-5"
          tableData={tableDataTopCreators}
          columnsData={tableColumnsTopCreators}
        />
      </div>
    </div>
  );
};

export default Visitors;
