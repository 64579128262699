export const tableColumnsTopCreators = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Purpose",
    accessor: "artworks",
  },
  {
    Header: "Status",
    accessor: "rating",
  },
];
