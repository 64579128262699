import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import HomeLayout from "layouts/home";
import VisitorLayout from "layouts/visitor";
import PreBooking from "layouts/home/PreBooking";
const App = () => {
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="/pre-booking" element={<PreBooking />} />
      <Route path="/visitor" element={<VisitorLayout />} />
      <Route path="/" element={<HomeLayout />} />
    </Routes>
  );
};

export default App;
