import React from "react";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import { BsPeopleFill } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import { TbGitPullRequest } from "react-icons/tb";
import Widget from "components/widget/Widget";
import Tabel from "views/admin/default/components/Table";

const Dashboard = () => {
  return (
    <div>
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4">
        <Widget
          icon={<IoIosPeople className="h-7 w-7" />}
          title={"Total Visitors"}
          isSelect={"Yes"}
          subtitle={"340"}
        />
        <Widget
          icon={<BsPeopleFill className="h-6 w-6" />}
          title={"Total Employees"}
          isSelect={"No"}
          subtitle={"642"}
        />
        <Widget
          icon={<TbGitPullRequest className="h-7 w-7" />}
          title={"Pending Approvals"}
          isSelect={"No"}
          subtitle={"574"}
        />
      </div>

      {/* Charts */}
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <div className="grid gap-5 rounded-[20px] ">
          <Tabel />
        </div>
        <div>
          <WeeklyRevenue />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
