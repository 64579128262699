import React, { useState } from "react";
import Card from "components/card";
import BarChart from "components/charts/BarChart";
import {
  barChartDataWeeklyRevenue,
  barChartOptionsWeeklyRevenue,
} from "variables/charts";
import { pieChartData, pieChartOptions } from "variables/charts";
import PieChart from "components/charts/PieChart";
import { MdBarChart } from "react-icons/md";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieChartCard from "views/admin/default/components/PieChartCard";

const WeeklyRevenue = () => {
  const [showBar, setShowBar] = useState(true);

  const changeGraph = () => {
    setShowBar(!showBar);
  };

  return (
    <Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center">
      <div className="mb-auto flex items-center justify-between px-6">
        <h2 className="text-lg font-bold text-navy-700 dark:text-white">
          This Week Report
        </h2>
        <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          {!showBar ? (
            <MdBarChart className="h-6 w-6" onClick={changeGraph} />
          ) : (
            <BiSolidPieChartAlt2 className="h-6 w-6" onClick={changeGraph} />
          )}
        </button>
      </div>

      <div className="md:mt-16 lg:mt-0">
        <div className="h-[250px] w-full xl:h-[350px]">
          {!showBar ? (
            <>
              <div className="flex flex-row justify-between px-6 pt-2">
                <div>
                  <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                    Visitors Report
                  </h4>
                </div>

                <div className="mb-6 flex items-center justify-center">
                  <select className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
                    <option value="monthly">This Month</option>
                    <option value="yearly">Current Year</option>
                  </select>
                </div>
              </div>
              <div className="mb-auto flex h-[300px] w-full items-center justify-center">
                <PieChart options={pieChartOptions} series={pieChartData} />
              </div>
            </>
          ) : (
            <BarChart
              chartData={barChartDataWeeklyRevenue}
              chartOptions={barChartOptionsWeeklyRevenue}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default WeeklyRevenue;
