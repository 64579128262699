import React from "react";
import avatar from "assets/img/avatars/avatar11.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";

const Banner = () => {
  return (
    <>
      <div className="flex  flex-col">
        {/* <div className="flex w-full min-w-[300px] flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none"> */}
        <Card
          extra={
            "items-center min-w-[300px] h-full p-[16px] bg-cover dark:!bg-navy-700 dark:shadow-none justify-between mb-5"
          }
        >
          {/* Background and profile */}
          <div
            className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
            style={{ backgroundImage: `url(${banner})` }}
          >
            <div className="absolute -bottom-12 flex h-[100px] w-[100px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700 dark:!bg-navy-700 dark:shadow-none">
              <img className="h-full w-full rounded-full" src={avatar} alt="" />
            </div>
          </div>

          {/* Name and position */}
          <div className="mt-16 flex flex-col items-center">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              Manish IC
            </h4>
            <p className="text-base font-normal text-gray-600">
              Employee : Product Manager
            </p>
          </div>

          {/* Post followers */}
        </Card>
        <Card>
          <div className="m-5 rounded-3xl bg-[#000] py-2 px-5 text-white">
            <p>Download I-Card</p>
          </div>
        </Card>
        {/* </div> */}
      </div>
    </>
  );
};

export default Banner;
