import react, { useState } from "react";
import Card from "components/card";
import ProfileCard from "../../views/admin/visitors/components/ProfileCard";
import General from "../../views/admin/profile/components/General";
import Navbar from "components/navbar";
import { FaArrowLeftLong } from "react-icons/fa6";
import { TbLogout, TbLogin } from "react-icons/tb";
import InputField from "../../components/fields/InputField";
import { Link } from "react-router-dom";

const Index = () => {
  const [visitslist, setVisitslist] = useState(true);
  const handlevisitslist = () => {
    setVisitslist(!visitslist);
  };
  return (
    <>
      <div className="flex flex-col gap-5 bg-lightPrimary p-5 dark:bg-navy-900">
        <Navbar />
        <div className="grid grid-cols-1 gap-5 lg:flex">
          <div className="flex flex-col gap-5 sm:flex-row">
            <ProfileCard />
            <Card extra={"h-[440px] w-full min-w-[300px] "}>
              <div className="flex h-fit items-center justify-between rounded-t-2xl bg-white px-4 pt-4 pb-[20px] shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
                <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                  Status
                </h4>
              </div>
              <div className="m-3 flex justify-between ">
                <button className="flex h-[100px] w-fit flex-col items-center justify-center rounded-xl bg-green-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200 ">
                  <TbLogin className="h-7 w-7" />
                  <h4 className="text-lg font-bold text-white">
                    Punch&nbsp;&nbsp;&nbsp;In
                  </h4>
                </button>
                <button className="flex h-[100px] w-fit flex-col items-center justify-center rounded-xl bg-red-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200">
                  <TbLogout className="h-7 w-7" />
                  <h4 className="text-lg font-bold text-white">Punch Out</h4>
                </button>
              </div>
              <div className="mx-3 mt-2 flex flex-col justify-between ">
                <p className="text-black text-lg">
                  <span className="font-bold">In Time : </span>27-02-2023
                  02:30:34
                </p>
                <p className="text-black text-lg">
                  <span className="font-bold">Out Time : </span>27-02-2023
                  02:30:34
                </p>
              </div>
              <InputField
                label="OTP"
                extra={"mx-3 mt-1"}
                placeholder="247-021"
                id="otp"
                type="text"
              />
            </Card>
          </div>

          <Card extra={"h-[440px] w-full min-w-[300px]"}>
            <div className="flex h-fit items-center justify-between rounded-t-2xl bg-white px-4 pt-4 pb-[20px] shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
              <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                My Appointment
              </h4>
            </div>

            <div className="w-full overflow-x-scroll p-4 md:overflow-x-hidden"></div>
          </Card>
        </div>

        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
          <General />
          <Card extra={"w-full h-[440px] min-w-[300px]"}>
            <div className="flex h-fit  items-center justify-between rounded-t-2xl bg-white px-4 pt-4 pb-[20px] shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
              {visitslist ? (
                <>
                  <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                    My All Visits
                  </h4>
                </>
              ) : (
                <>
                  <Link onClick={handlevisitslist}>
                    <FaArrowLeftLong className="h-7 w-7" />
                  </Link>
                </>
              )}
            </div>

            <div className="w-full overflow-y-scroll p-5">
              <div className="grid gap-4">
                <Link onClick={handlevisitslist}>
                  <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      Stanford University
                    </p>
                    <p className="text-sm text-gray-600">Education</p>
                  </div>
                </Link>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Index;
