import Card from "components/card";

const Widget = ({ icon, title, subtitle, isSelect }) => {
  return (
    <>
      <Card extra="!flex-row items-center py-3 min-w-[300px] px-3 min-h-[85px]">
        <div className="flex items-center">
          <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
            <span className="flex items-center text-brand-500 dark:text-white">
              {icon}
            </span>
          </div>
        </div>
        <div className="flex">
          <div className="h-50 ml-4 flex w-auto flex-col justify-center">
            <div className="flex items-center justify-between">
              <p className="flex items-center font-dm text-sm font-medium text-gray-600">
                {title}
              </p>

              {isSelect === "Yes" && (
                <div className="ml-10 flex text-gray-600">
                  <select
                    id="visits"
                    name="visits"
                    autoComplete="visits-period"
                    class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option>Today's</option>
                    <option>This Week</option>
                    <option>This Month</option>
                  </select>
                </div>
              )}
            </div>
            <h4 className="flex text-xl font-bold text-navy-700 dark:text-white">
              {subtitle}
            </h4>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Widget;
