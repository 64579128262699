import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import Visitors from "views/admin/visitors";
import Profile from "views/admin/profile";
import { LiaUserSecretSolid } from "react-icons/lia";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import { MdHome, MdPerson, MdLock } from "react-icons/md";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Members",
    layout: "/admin",
    path: "member",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Visitors />,
    secondary: true,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
];
export default routes;
