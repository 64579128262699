import React, { useState } from "react";
import EmployeeTable from "./EmployeeTable";
import VisitorTable from "./VisitorTable";
import AdminTable from "./AdminTable";
import { columnsDataCheck } from "../variables/columnsData";
import tableDataCheck from "../variables/tableDataCheck.json";
import Card from "components/card";

const Tabel = () => {
  const [selectedOption, setSelectedOption] = useState("allEmployee");

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {selectedOption === "allEmployee" && "All Employees"}
            {selectedOption === "allVisitor" && "All Visitors"}
            {selectedOption === "allAdmin" && "All Admin"}
          </div>
          <select
            id="options"
            name="options"
            autoComplete="options"
            value={selectedOption}
            onChange={handleDropdownChange}
            className="block rounded-md border-0 p-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            <option value="allEmployee">All Employees</option>
            <option value="allVisitor">All Visitors</option>
            <option value="allAdmin">All Admin</option>
          </select>
        </header>

        {selectedOption === "allEmployee" && (
          <EmployeeTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        )}
        {selectedOption === "allVisitor" && (
          <VisitorTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        )}
        {selectedOption === "allAdmin" && (
          <AdminTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        )}
      </Card>
    </>
  );
};

export default Tabel;
